<template>
  <div class="about-container pre-formatted">
    <div class="container" v-html="content">{{ content }}</div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Terms and conditions",
  data() {
    return {
      content: ""
    };
  },
  methods: {
    ...mapActions("pages", ["fetchPageDetailsBySlug"])
  },
  mounted() {
    this.fetchPageDetailsBySlug("terms-and-conditions").then(res => {
      this.content = res.data.data;
    });
  }
};
</script>
<style lang="scss">
.pre-formatted {
  white-space: pre-wrap;
}

.about-container * {
  font-family: "Open Sans", sans-serif !important;
}
</style>
